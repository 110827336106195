import React from "react";

const Footer = () => (
  <section className="d-flex flex-column mt-3">
    <h2 className="text-center text-decoration-underline">Skills</h2>
    <div className="stack d-grid text-light">
      <ul>
        <li>Javascript</li>
        <li>React Native</li>
        <li>React.js</li>
        <li>Alipay Mini-App Programming Language</li>
        <li>Ruby</li>
        <li>Ruby on Rails</li>
        <li>Node.js</li>
        <li>Tailwind</li>
      </ul>
      <ul>
        <li>Mentorship</li>
        <li>Leadership</li>
        <li>Peer-to-peer Review</li>
        <li>Pair Programming</li>
      </ul>
      <ul>
        <li>Jest</li>
        <li>Capybara</li>
        <li>RSpec</li>
      </ul>
      <ul>
        <li>Github</li>
        <li>Netlify</li>
        <li>Heroku</li>
      </ul>
    </div>
  </section>
);

export default Footer;
